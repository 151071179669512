import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import FilterTag from "./FilterTag";

const Logout = () => {
  const appContext = useContext(AppContext);
  const { userName, logout } = appContext;
  return (
    <div className="flex">
      <div className="pt-3">
        <FilterTag />
      </div>
      <div className="pt-2">Logged in as {userName}</div>
      <button
        className="font-inter_semibold text-gray-600 hover:text-white hover:bg-green-400
        border border-gray-200 px-4 py-1 mx-2 focus:outline-none rounded"
        onClick={() => logout()}
      >
        Logout
      </button>
    </div>
  );
};

export default Logout;
