import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import SortButton from "./SortButton";
import FilterIndustryButton from "./FilterIndustryButton";

const FilterIndustry = () => {
  const appContext = useContext(AppContext);
  const {
    endpointParms,
    showFilterIndustry,
    clearFilterIndustry,
    toggleFilterIndustry
  } = appContext;
  const showClear =
    endpointParms.industry === "ANY" || endpointParms.industry.length === 0
      ? "hidden"
      : "";
  const showFilter = showFilterIndustry ? "" : "hidden";
  const statusIndicator = showFilterIndustry ? "- " : "+ ";
  const filterColor = endpointParms.sort === "industry" ? "text-green-600" : "";

  return (
    <section className="bg-white font-inter_semibold border border-gray-200 rounded  mb-1 mx-5 p-3">
      {/* FILTER HEADER */}
      <div className="flex">
        <div className="flex w-1/6 text-center ">
          <SortButton sortField="industry" sortDirection="asc" />
          <SortButton sortField="industry" sortDirection="desc" />
        </div>
        <div
          className="flex w-5/6 justify-between cursor-pointer "
          onClick={() => toggleFilterIndustry()}
        >
          <div className={filterColor + " justify-start hover:text-green-600"}>
            {statusIndicator + " "}Industry
          </div>
          <div className="justify-start text-gray-500">
            {endpointParms.industry}
          </div>
        </div>
      </div>

      {/* FILTER BUTTONS */}
      <div className={showFilter + " flex flex-col mt-3"}>
        <div className="w-full justify-center py-2">
          <button
            className={
              showClear +
              " font-inter_semibold text-gray-600 px-4 py-1 mx-2 focus:outline-none rounded"
            }
            onClick={() => clearFilterIndustry()}
          >
            Clear
          </button>
        </div>
        <div className="flex justify-center">
          <div className="w-5/6 grid grid-cols-2">
            <div className="pr-2">
              <FilterIndustryButton
                industry="Advertising & Marketing"
                key="1"
              />
              <FilterIndustryButton
                industry="Business Products & Services"
                key="2"
              />
              <FilterIndustryButton industry="Computer Hardware" key="3" />
              <FilterIndustryButton industry="Construction" key="4" />
              <FilterIndustryButton
                industry="Consumer Products & Services"
                key="5"
              />
              <FilterIndustryButton industry="Education" key="6" />
              <FilterIndustryButton industry="Energy" key="7" />
              <FilterIndustryButton industry="Engineering" key="8" />
              <FilterIndustryButton industry="Environmental Services" key="9" />
              <FilterIndustryButton industry="Financial Services" key="10" />
              <FilterIndustryButton industry="Food & Beverage" key="11" />
              <FilterIndustryButton industry="Government Services" key="12" />
              <FilterIndustryButton industry="Health" key="13" />
              <FilterIndustryButton industry="Human Resources" key="14" />
            </div>
            <div className="pl-2">
              <FilterIndustryButton industry="Insurance" key="15" />
              <FilterIndustryButton industry="IT Management" key="16" />
              <FilterIndustryButton industry="IT Services" key="17" />
              <FilterIndustryButton industry="IT System Development" key="18" />
              <FilterIndustryButton
                industry="Logistics & Transportation"
                key="19"
              />
              <FilterIndustryButton industry="Manufacturing" key="20" />
              <FilterIndustryButton industry="Media" key="21" />
              <FilterIndustryButton industry="Real Estate" key="22" />
              <FilterIndustryButton industry="Retail" key="23" />
              <FilterIndustryButton industry="Security" key="24" />
              <FilterIndustryButton industry="Software" key="25" />
              <FilterIndustryButton industry="Telecommunications" key="26" />
              <FilterIndustryButton industry="Travel & Hospitality" key="27" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FilterIndustry;
