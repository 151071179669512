import React, { useState } from "react";
import axios from "axios";
import { initialCompany, initialEndpointParms } from "./initialValues";
import {
  NOT_LOGGED_IN,
  LOG_IN_FORM,
  SIGN_UP_FORM,
  LOGGED_IN
} from "../constants/AuthStatus";

const AppContext = React.createContext();

const AppProvider = props => {
  //
  // STATE ----------------------------------------------------------------------------------------
  //
  // FILTERS AND SORT

  const [filterTag, setFilterTag] = useState("ANY");
  const [endpointParms, setEndpointParms] = useState(initialEndpointParms);
  const [showFilterName, setShowFilterName] = useState(false);
  const [showFilterRank, setShowFilterRank] = useState(false);
  const [showFilterUsstate, setShowFilterUsstate] = useState(false);
  const [showFilterIndustry, setShowFilterIndustry] = useState(false);
  const [showFilterRevenue, setShowFilterRevenue] = useState(false);
  const [showFilterGrowth, setShowFilterGrowth] = useState(false);
  const [showFilterFounded, setShowFilterFounded] = useState(false);
  const [showFilterWorkers, setShowFilterWorkers] = useState(false);
  const [showFilterYearsOnList, setShowFilterYearsOnList] = useState(false);
  const [showFilterMetro, setShowFilterMetro] = useState(false);

  // AUTH

  const [authStatus, setAuthStatus] = useState(NOT_LOGGED_IN);
  const [errorMessage, setErrorMessage] = useState("");
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState("");
  const [userNameInput, setUserNameInput] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  // OTHER STATE : HOST, SELECTED COMPANY, TAGS, COMPANY LIST ENDPOINT

  let apiHost = "";
  if (process.env.NODE_ENV === "development") {
    apiHost = "http://localhost:8000/";
  } else if (process.env.NODE_ENV === "production") {
    apiHost = "https://inc5000api.bob-humphrey.com/";
  }
  const [hostName] = useState(apiHost);
  const [companyData, setCompanyData] = useState(initialCompany);
  const [tags, setTags] = useState([]);
  const [companyListEndpoint, setCompanyListEndpoint] = useState(
    hostName + "api/companies/ANY/1/ANY/ANY/0/0/ANY/0/0/ANY/id/asc?page=1"
  );

  function updateErrorMessage(message) {
    setErrorMessage(message);
  }

  // CUSTOMER LIST --------------------------------------------------------------------------------

  // UPDATE API ENDPOINT

  // This application consists of a number of controls that can be used
  // to filter and sort the Inc 5000 list of companies by various criteria.
  // These controls work by updating the values in the enpointParms
  // object, which is then used to create a new companyListEndpoint.  When the
  // companyListEndpoint changes, the CompanyList component calls the API and
  // produces a new list of companies for display.

  function updateEndpoint(endpointParms) {
    setCompanyListEndpoint(
      hostName +
        "api/companies/" +
        endpointParms.name +
        "/" +
        endpointParms.rank +
        "/" +
        endpointParms.usstate +
        "/" +
        endpointParms.industry +
        "/" +
        endpointParms.revenue +
        "/" +
        endpointParms.growth +
        "/" +
        endpointParms.founded +
        "/" +
        endpointParms.workers +
        "/" +
        endpointParms.yearsOnList +
        "/" +
        endpointParms.metro +
        "/" +
        endpointParms.sort +
        "/" +
        endpointParms.sortDirection +
        "?page=1"
    );
  }

  // NAVIGATE THE CUSTOMER LIST DISPLAY

  function handleChangePage(updatedEndpoint) {
    if (updatedEndpoint === null) return;
    setCompanyListEndpoint(updatedEndpoint);
  }

  function handleSelectCurrentCompany(updatedCurrentCompany) {
    const companyEndpoint = hostName + "api/company/" + updatedCurrentCompany;
    axios.get(companyEndpoint).then(response => {
      setCompanyData(response.data);
      // GET TAGS FOR CURRENT CUSTOMER IF USER IS LOGGED IN
      if (userId) {
        axios
          .get(hostName + "api/tags/" + userId + "/" + updatedCurrentCompany)
          .then(
            response => {
              setCustomerTags(response.data);
              setErrorMessage("");
            },
            error => {
              setErrorMessage("Database error reading company tags");
            }
          );
      }
    });
  }

  // ADD AND REMOVE TAGS FROM INDIVIDUAL COMPANIES ------------------------------------------------

  function setCustomerTags(data) {
    let tags = [];
    data.data.map(item => tags.push(item.tag));
    setTags(tags);
  }

  function handleToggleTag(tag) {
    let updatedTags = [...tags];
    const i = updatedTags.indexOf(tag);
    if (i === -1) {
      // Add the tag.
      updatedTags.push(tag);
      axios.defaults.withCredentials = true;
      axios
        .post(hostName + "api/tags", {
          user: userId,
          company: companyData.id,
          tag: tag
        })
        .then(
          response => {
            setErrorMessage("");
          },
          error => {
            setErrorMessage("Database error adding the tag");
          }
        );
      setTags(updatedTags);
    } else {
      // Remove the tag.
      updatedTags.splice(i, 1);
      let tagId = null;
      axios.defaults.withCredentials = true;
      axios
        .get(hostName + "api/tags/" + userId + "/" + companyData.id + "/" + tag)
        .then(response => {
          tagId = response.data.data[0].id;
          axios.delete(hostName + "api/tags/delete/" + tagId).then(
            response => {
              setErrorMessage("");
            },
            error => {
              setErrorMessage("Database error removing the tag");
            }
          );
        });
      setTags(updatedTags);
    }
  }

  // AUTH, LOGIN AND LOGOUT -----------------------------------------------------------------------

  function startLogin() {
    setAuthStatus(LOG_IN_FORM);
  }
  function startSignup() {
    setAuthStatus(SIGN_UP_FORM);
  }
  function handleUserNameInput(changeEvent) {
    let updatedUserName = changeEvent.target.value;
    setUserNameInput(updatedUserName);
  }
  function handleUserEmail(changeEvent) {
    let updatedUserEmail = changeEvent.target.value;
    setUserEmail(updatedUserEmail);
  }
  function handleUserPassword(changeEvent) {
    let updatedUserPassword = changeEvent.target.value;
    setUserPassword(updatedUserPassword);
  }
  function login() {
    axios.defaults.withCredentials = true;
    // CSRF COOKIE
    axios.get(hostName + "airlock/csrf-cookie").then(
      response => {
        // LOGIN
        axios
          .post(hostName + "api/login", {
            email: userEmail,
            password: userPassword
          })
          .then(
            response => {
              // GET USER
              axios.get(hostName + "api/user").then(
                response => {
                  const userId = response.data.id;
                  setUserId(response.data.id);
                  setUserName(response.data.name);
                  setErrorMessage("");
                  setAuthStatus(LOGGED_IN);
                  // GET TAGS FOR CURRENT CUSTOMER
                  axios
                    .get(
                      hostName + "/api/tags/" + userId + "/" + companyData.id
                    )
                    .then(response => {
                      setCustomerTags(response.data);
                      setErrorMessage("");
                    });
                },
                // GET USER ERROR
                error => {
                  setErrorMessage("Could not complete the login");
                }
              );
            },
            // LOGIN ERROR
            error => {
              if (error.response) {
                setErrorMessage(error.response.data.message);
              } else {
                setErrorMessage("Could not complete the login");
              }
            }
          );
      },
      // COOKIE ERROR
      error => {
        setErrorMessage("Could not complete the login");
      }
    );
  }

  function signup() {
    axios.defaults.withCredentials = true;
    // CSRF COOKIE
    axios.get(hostName + "airlock/csrf-cookie").then(
      response => {
        // SIGNUP / REGISTER
        axios
          .post(hostName + "api/register", {
            name: userNameInput,
            email: userEmail,
            password: userPassword
          })
          .then(
            response => {
              // GET USER
              axios.get(hostName + "api/user").then(
                response => {
                  setUserId(response.data.id);
                  setUserName(response.data.name);
                  setErrorMessage("");
                  setAuthStatus(LOGGED_IN);
                },
                // GET USER ERROR
                error => {
                  setErrorMessage("Could not complete the sign up");
                }
              );
            },
            // SIGNUP ERROR
            error => {
              if (error.response.data.errors.name) {
                setErrorMessage(error.response.data.errors.name[0]);
              } else if (error.response.data.errors.email) {
                setErrorMessage(error.response.data.errors.email[0]);
              } else if (error.response.data.errors.password) {
                setErrorMessage(error.response.data.errors.password[0]);
              } else if (error.response.data.message) {
                setErrorMessage(error.response.data.message);
              } else {
                setErrorMessage("Could not complete the sign up");
              }
            }
          );
      },
      // COOKIE ERROR
      error => {
        setErrorMessage("Could not complete the sign up");
      }
    );
  }

  function logout() {
    axios.defaults.withCredentials = true;
    axios.get(hostName + "api/logout");
    clearFilterTag();
    setUserId(0);
    setUserName("");
    setUserNameInput("");
    setUserEmail("");
    setUserPassword("");
    setAuthStatus(NOT_LOGGED_IN);
  }

  // FILTER AND SORT THE LIST OF 5000 COMPANIES ---------------------------------------------------

  // TAG FILTER

  function handleFilterTag(tag) {
    setFilterTag(tag);
    setCompanyListEndpoint(
      hostName + "api/companies/tag/" + userId + "/" + tag + "?page=1"
    );
  }
  function clearFilterTag() {
    setFilterTag("ANY");
    const updatedEndpointParms = { ...endpointParms };
    updateEndpoint(updatedEndpointParms);
  }

  // NAME FILTER

  function handleFilterName(changeEvent) {
    let updatedName = changeEvent.target.value;
    const updatedEndpointParms = { ...endpointParms, name: updatedName };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function clearFilterName() {
    const updatedEndpointParms = { ...endpointParms, name: "ANY" };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function toggleFilterName() {
    setShowFilterName(!showFilterName);
  }

  // RANK FILTER

  function handleFilterRank(changeEvent) {
    let updatedRank = changeEvent.target.value;
    if (updatedRank.length > 4) return;
    const updatedEndpointParms = { ...endpointParms, rank: updatedRank };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function clearFilterRank() {
    const updatedEndpointParms = { ...endpointParms, rank: 1 };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function toggleFilterRank() {
    setShowFilterRank(!showFilterRank);
  }

  // US STATE FILTER

  function handleFilterUsstate(event) {
    let updatedUsstate = event.target.dataset.name;
    const updatedEndpointParms = { ...endpointParms, usstate: updatedUsstate };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function clearFilterUsstate() {
    const updatedEndpointParms = { ...endpointParms, usstate: "ANY" };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function toggleFilterUsstate() {
    setShowFilterUsstate(!showFilterUsstate);
  }

  // INDUSTRY FILTER

  function handleFilterIndustry(updatedIndustry) {
    const updatedEndpointParms = {
      ...endpointParms,
      industry: updatedIndustry
    };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function clearFilterIndustry() {
    const updatedEndpointParms = { ...endpointParms, industry: "ANY" };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function toggleFilterIndustry() {
    setShowFilterIndustry(!showFilterIndustry);
  }

  // REVENUE FILTER

  function handleFilterRevenue(changeEvent) {
    let updatedRevenue = changeEvent.target.value;
    const updatedEndpointParms = { ...endpointParms, revenue: updatedRevenue };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function clearFilterRevenue() {
    const updatedEndpointParms = { ...endpointParms, revenue: 0 };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function toggleFilterRevenue() {
    setShowFilterRevenue(!showFilterRevenue);
  }

  // GROWTH FILTER

  function handleFilterGrowth(changeEvent) {
    let updatedGrowth = changeEvent.target.value;
    const updatedEndpointParms = { ...endpointParms, growth: updatedGrowth };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function clearFilterGrowth() {
    const updatedEndpointParms = { ...endpointParms, growth: 0 };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function toggleFilterGrowth() {
    setShowFilterGrowth(!showFilterGrowth);
  }

  // FOUNDED FILTER

  function handleFilterFounded(changeEvent) {
    let updatedFounded = changeEvent.target.value;
    if (updatedFounded.length > 4) return;
    const updatedEndpointParms = { ...endpointParms, founded: updatedFounded };
    if (updatedFounded.length === 4) {
      updateEndpoint(updatedEndpointParms);
    }
    setEndpointParms(updatedEndpointParms);
  }
  function clearFilterFounded() {
    const updatedEndpointParms = { ...endpointParms, founded: "ANY" };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function toggleFilterFounded() {
    setShowFilterFounded(!showFilterFounded);
  }

  // WORKERS FILTER

  function handleFilterWorkers(changeEvent) {
    let updatedWorkers = changeEvent.target.value;
    const updatedEndpointParms = { ...endpointParms, workers: updatedWorkers };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function clearFilterWorkers() {
    const updatedEndpointParms = { ...endpointParms, workers: 0 };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function toggleFilterWorkers() {
    setShowFilterWorkers(!showFilterWorkers);
  }

  // YEARS ON LIST FILTER

  function handleFilterYearsOnList(changeEvent) {
    let updatedYearsOnList = changeEvent.target.value;
    const updatedEndpointParms = {
      ...endpointParms,
      yearsOnList: updatedYearsOnList
    };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function clearFilterYearsOnList() {
    const updatedEndpointParms = { ...endpointParms, yearsOnList: 0 };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function toggleFilterYearsOnList() {
    setShowFilterYearsOnList(!showFilterYearsOnList);
  }

  // METRO FILTER

  function handleFilterMetro(changeEvent) {
    let updatedMetro = changeEvent.target.value;
    const updatedEndpointParms = { ...endpointParms, metro: updatedMetro };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function clearFilterMetro() {
    const updatedEndpointParms = { ...endpointParms, metro: "ANY" };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }
  function toggleFilterMetro() {
    setShowFilterMetro(!showFilterMetro);
  }

  // SORTING

  function sortRecords(updatedSortField, updatedSortDirection) {
    const updatedEndpointParms = {
      ...endpointParms,
      sort: updatedSortField,
      sortDirection: updatedSortDirection
    };
    updateEndpoint(updatedEndpointParms);
    setEndpointParms(updatedEndpointParms);
  }

  return (
    <AppContext.Provider
      value={{
        hostName,
        authStatus,
        companyListEndpoint,
        endpointParms,
        sortRecords,
        companyData,
        tags,
        handleToggleTag,
        userId,
        userName,
        userNameInput,
        userEmail,
        userPassword,
        errorMessage,
        updateErrorMessage,
        handleUserNameInput,
        handleUserEmail,
        handleUserPassword,
        login,
        logout,
        signup,
        startLogin,
        startSignup,
        handleChangePage,
        handleFilterName,
        clearFilterName,
        showFilterName,
        toggleFilterName,
        handleFilterRank,
        clearFilterRank,
        showFilterRank,
        toggleFilterRank,
        handleFilterUsstate,
        clearFilterUsstate,
        showFilterUsstate,
        toggleFilterUsstate,
        handleFilterIndustry,
        clearFilterIndustry,
        showFilterIndustry,
        toggleFilterIndustry,
        handleFilterRevenue,
        clearFilterRevenue,
        showFilterRevenue,
        toggleFilterRevenue,
        handleFilterGrowth,
        clearFilterGrowth,
        showFilterGrowth,
        toggleFilterGrowth,
        handleFilterFounded,
        clearFilterFounded,
        showFilterFounded,
        toggleFilterFounded,
        handleFilterWorkers,
        clearFilterWorkers,
        showFilterWorkers,
        toggleFilterWorkers,
        handleFilterYearsOnList,
        clearFilterYearsOnList,
        showFilterYearsOnList,
        toggleFilterYearsOnList,
        handleFilterMetro,
        clearFilterMetro,
        showFilterMetro,
        toggleFilterMetro,
        handleFilterTag,
        clearFilterTag,
        filterTag,
        handleSelectCurrentCompany
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
