import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import TagButton from "./TagButton";

const Tags = () => {
  const appContext = useContext(AppContext);
  const { userId } = appContext;
  if (!userId) return null;

  return (
    <div className="flex">
      <TagButton color="orange" key="1" />
      <TagButton color="yellow" key="2" />
      <TagButton color="green" key="3" />
      <TagButton color="teal" key="4" />
    </div>
  );
};

export default Tags;
