import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";

const FilterIndustryButton = props => {
  const appContext = useContext(AppContext);
  const { handleFilterIndustry } = appContext;
  const industry = props.industry.substring(0, 19);

  return (
    <button
      className="w-full font-inter_light text-xs hover:text-green-600 text-left focus:outline-none rounded"
      onClick={() => handleFilterIndustry(industry)}
    >
      {industry}
    </button>
  );
};

export default FilterIndustryButton;
