import React from "react";

const SmallScreen = () => {
  return (
    <div className="w-full py-32">
      <div className="font-inter_semibold text-2xl text-green-600 text-center p-6">
        Inc 5000 Companies
      </div>
      <div className="font-inter_light text-xl text-center p-6">
        A wider screen is required for this application.
      </div>
    </div>
  );
};

export default SmallScreen;
