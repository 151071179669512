import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import SortButton from "./SortButton";

const FilterMetro = () => {
  const appContext = useContext(AppContext);
  const {
    endpointParms,
    showFilterMetro,
    handleFilterMetro,
    clearFilterMetro,
    toggleFilterMetro
  } = appContext;
  const showMetroInput =
    endpointParms.metro === "ANY" ? "" : endpointParms.metro;
  const showClear =
    endpointParms.metro === "ANY" || endpointParms.metro.length === 0
      ? "hidden"
      : "";
  const showFilter = showFilterMetro ? "" : "hidden";
  const statusIndicator = showFilterMetro ? "- " : "+ ";
  const filterColor = endpointParms.sort === "metro" ? "text-green-600" : "";

  return (
    <section className="bg-white font-inter_semibold border border-gray-200 rounded  mb-1 mx-5 p-3">
      {/* FILTER HEADER */}
      <div className="flex">
        <div className="flex w-1/6 text-center ">
          <SortButton sortField="metro" sortDirection="asc" />
          <SortButton sortField="metro" sortDirection="desc" />
        </div>
        <div
          className="flex w-5/6 justify-between cursor-pointer "
          onClick={() => toggleFilterMetro()}
        >
          <div className={filterColor + " justify-start hover:text-green-600"}>
            {statusIndicator + " "}Metro
          </div>
          <div className="justify-start text-gray-500">
            {endpointParms.metro}
          </div>
        </div>
      </div>

      {/* FILTER TEXTBOX AND BUTTONS */}
      <div className={showFilter + " flex justify-center mt-3"}>
        <div className="w-5/6 grid grid-cols-3 bg-gray-200 rounded">
          <div className="py-3 text-center">Metro</div>
          <div className="py-2 ">
            <input
              className="w-full py-1 px-1 border border-gray-200 rounded"
              type="text"
              value={showMetroInput}
              onChange={handleFilterMetro}
            />
          </div>
          <div className="py-2">
            <button
              className={
                showClear +
                " font-inter_semibold text-gray-600 px-4 py-1 mx-2 focus:outline-none rounded"
              }
              onClick={() => clearFilterMetro()}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FilterMetro;
