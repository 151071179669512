export const initialEndpointParms = {
  name: "ANY",
  rank: 1,
  usstate: "ANY",
  industry: "ANY",
  revenue: 0,
  growth: 0,
  founded: "ANY",
  workers: 0,
  yearsOnList: 0,
  metro: "ANY",
  sort: "id",
  sortDirection: "asc"
};

export const initialCompany = {
  id: 1,
  name: "Freestar",
  city: "Phoenix",
  state: "AZ",
  url: "http://freestar.com/",
  profile: "https://www.inc.com/profile/freestar",
  industry: "Advertising & Marketing",
  revenue_string: "36.9 Million",
  growth: "36680.4",
  founded: "2015",
  workers: "40",
  years_on_list: "1",
  metro: "Phoenix"
};
