import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import LoginSignup from "./LoginSignup";
import Logout from "./Logout";
import NotLoggedIn from "./NotLoggedIn";

import {
  NOT_LOGGED_IN,
  LOG_IN_FORM,
  SIGN_UP_FORM,
  LOGGED_IN
} from "../constants/AuthStatus";

const Header = () => {
  const appContext = useContext(AppContext);
  const { authStatus, errorMessage } = appContext;
  const showNotLoggedIn = authStatus === NOT_LOGGED_IN ? "" : "hidden";
  const showLoginForm = authStatus === LOG_IN_FORM ? "" : "hidden";
  const showSignupForm = authStatus === SIGN_UP_FORM ? "" : "hidden";
  const showLoggedIn = authStatus === LOGGED_IN ? "" : "hidden";
  const title = authStatus === SIGN_UP_FORM ? "Inc 5000" : "Inc 5000 Companies";
  const shortTitle = authStatus === SIGN_UP_FORM ? "" : "Inc 5000";

  return (
    <header className="flex justify-between font-inter bg-white px-6 xl:px-10">
      <h1 className="hidden xl:block justify-begin font-inter_semibold text-2xl text-green-600 py-4">
        {title}
      </h1>
      <h1 className="xl:hidden justify-begin font-inter_semibold text-2xl text-green-600 py-4">
        {shortTitle}
      </h1>

      <div className="flex justify-end">
        <div className="text-red-700 py-5 mr-5">{errorMessage}</div>
        <div className={showNotLoggedIn + " justify-end py-4"}>
          <NotLoggedIn />
        </div>
        <div className={showLoginForm + " justify-end py-4"}>
          <LoginSignup option="login" />
        </div>
        <div className={showSignupForm + " justify-end py-4"}>
          <LoginSignup option="signup" />
        </div>
        <div className={showLoggedIn + " justify-end py-3"}>
          <Logout />
        </div>
      </div>
    </header>
  );
};

export default Header;
