import React from "react";
import logo from "./../images/bh-logo-grey.gif";

const Footer = () => {
  return (
    <footer className="w-full justify-center text-gray-600 pt-4 pb-16 ">
      <div className="container w-1/5 text-xs font-inter_light mx-auto">
        Based on{" "}
        <a
          className="underline"
          href="https://www.inc.com/inc5000/2019/top-private-companies-2019-inc5000.html"
        >
          2019 Inc. 5000: The Most Successful Companies in America
        </a>
        . Data provided by{" "}
        <a
          className="underline"
          href="https://data.world/aurielle/inc-5000-2019"
        >
          data-world
        </a>
        .
      </div>
      <div className="flex w-full justify-center pt-12">
        <div className="w-16">
          <a href="https://bob-humphrey.com">
            <img src={logo} alt="Logo" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
