import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { FaTag } from "react-icons/fa";

const TagFilterButton = props => {
  const appContext = useContext(AppContext);
  const { filterTag, handleFilterTag } = appContext;
  const { color } = props;
  const darkness = filterTag === color ? "-700" : "-200";
  const iconColor = "text-" + color + darkness;

  return (
    <div>
      <button
        className={iconColor + " text-2xl leading-none mr-3 focus:outline-none"}
        onClick={() => handleFilterTag(color)}
      >
        <FaTag />
      </button>
    </div>
  );
};

export default TagFilterButton;
