import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";

const NotLoggedIn = () => {
  const appContext = useContext(AppContext);
  const { startLogin, startSignup } = appContext;
  return (
    <div className="flex">
      <div className="pt-1 mr-3">To tag company records</div>
      <button
        className="font-inter_semibold text-gray-600 hover:text-white hover:bg-green-400
    border border-gray-200 px-4 py-1 mr-3 focus:outline-none rounded"
        onClick={() => startSignup()}
      >
        Sign Up
      </button>
      <button
        className="font-inter_semibold text-gray-600 hover:text-white hover:bg-green-400
  border border-gray-200 px-4 py-1 focus:outline-none rounded"
        onClick={() => startLogin()}
      >
        Log In
      </button>
    </div>
  );
};

export default NotLoggedIn;
