import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../contexts/AppContext";
import Pagination from "./Pagination";
import CompanyListButton from "./CompanyListButton";

const CompanyList = () => {
  const appContext = useContext(AppContext);
  const {
    companyListEndpoint,
    filterTag,
    endpointParms,
    companyData,
    updateErrorMessage
  } = appContext;
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      axios.get(companyListEndpoint).then(
        response => {
          setData(response.data);
          //updateErrorMessage("");
        },
        error => {
          updateErrorMessage("Database error reading company information");
        }
      );
    }
    fetchData();
  }, [updateErrorMessage, companyListEndpoint]);

  if (data === null) return null;
  if (filterTag === null) return null;
  let rankFieldLabel = "";
  switch (endpointParms.sort) {
    case "id":
    case "name":
      break;
    default:
      rankFieldLabel = endpointParms.sort;
      break;
  }
  return (
    <section className="w-full p-5">
      <Pagination data={data} />
      <div className="flex flex-col">
        <div className="flex justify-between font-inter_light text-xs uppercase px-2 py-2">
          <div className="justify-start">
            <span className="pr-3">RANK</span>
            NAME
          </div>
          <div className="justify-end font-inter_light">{rankFieldLabel}</div>
        </div>
        {data.data.map(function(company, index) {
          // Join queries that filter customers by tag need to use the company.company
          // attribute to identify the company.  Other queries that don't have a join
          // use the company.id attribute.
          const companyId = filterTag === "ANY" ? company.id : company.company;
          const textColor =
            companyData.id === companyId ? "text-green-600" : "text-gray-700";
          const companyName = company.name.substring(0, 19);
          let rankField = "";
          switch (endpointParms.sort) {
            case "id":
            case "name":
              break;
            case "industry":
            case "metro":
              rankField = company[endpointParms.sort].substring(0, 10);
              break;
            default:
              rankField = company[endpointParms.sort];
              break;
          }
          return (
            <CompanyListButton
              textColor={textColor}
              companyId={companyId}
              companyName={companyName}
              rankField={rankField}
              key={index}
            />
          );
        })}
      </div>
    </section>
  );
};

export default CompanyList;
