import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import Tags from "./Tags";

const Company = () => {
  const appContext = useContext(AppContext);
  const { companyData } = appContext;
  const data = companyData;

  if (!data) {
    return null;
  }
  const url = data.url.startsWith("http") ? data.url : "http://" + data.url;

  return (
    <section className="w-full bg-white font-inter_medium border border-gray-200 p-5 m-5 rounded">
      <div className="text-2xl font-inter_semibold text-green-600 leading-tight mb-2">
        {data.name}
      </div>
      <div className="text-xl leading-tight mb-2">
        {data.city + ", " + data.state}
      </div>
      <div className="mb-2">
        <a
          className="font-inter_medium
          hover:text-green-600 mb-2 focus:outline-none"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Company website
        </a>
      </div>
      <div className="mb-4">
        <a
          className="font-inter_medium hover:text-green-600 focus:outline-none"
          href={data.profile}
          target="_blank"
          rel="noopener noreferrer"
        >
          Company profile
        </a>
      </div>
      <Tags />
      <div className="w-full text-left text-xl text-gray-600 mt-16">
        <div className="flex mb-2">
          <div className="font-inter_thin pr-5">Rank</div>
          <div className="">{data.id}</div>
        </div>
        <div className="flex mb-2">
          <div className="font-inter_thin pr-5">Industry</div>
          <div className="">{data.industry}</div>
        </div>

        <div className="flex mb-2">
          <div className="font-inter_thin pr-5">Revenue</div>
          <div className="">${data.revenue_string}</div>
        </div>
        <div className="flex mb-2">
          <div className="font-inter_thin pr-5">Growth</div>
          <div className="">{data.growth}%</div>
        </div>
        <div className="flex mb-2">
          <div className="font-inter_thin pr-5">Founded</div>
          <div className="">{data.founded}</div>
        </div>
        <div className="flex mb-2">
          <div className="font-inter_thin pr-5">Workers</div>
          <div className="">{data.workers}</div>
        </div>
        <div className="flex mb-2">
          <div className="font-inter_thin pr-5">Years On Inc 5000 List</div>
          <div className="">{data.years_on_list}</div>
        </div>
        <div className="flex mb-2">
          <div className="font-inter_thin pr-5">Metro</div>
          <div className="">{data.metro}</div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Company);
