import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { FaTag } from "react-icons/fa";

const TagButton = props => {
  function toggleTag(color) {
    handleToggleTag(color);
  }
  const appContext = useContext(AppContext);
  const { tags, handleToggleTag } = appContext;
  const { color } = props;
  const darkness = tags.includes(color) ? "700" : "200";
  const iconColor = "text-" + color + "-" + darkness;

  return (
    <div>
      <button
        className={iconColor + " text-2xl mr-3 py-0 focus:outline-none"}
        onClick={() => toggleTag(color)}
      >
        <FaTag />
      </button>
    </div>
  );
};

export default TagButton;
