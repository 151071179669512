import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import FilterName from "./FilterName";
import FilterRank from "./FilterRank";
import FilterState from "./FilterState";
import FilterIndustry from "./FilterIndustry";
import FilterRevenue from "./FilterRevenue";
import FilterFounded from "./FilterFounded";
import FilterGrowth from "./FilterGrowth";
import FilterWorkers from "./FilterWorkers";
import FilterYearsOnList from "./FilterYearsOnList";
import FilterMetro from "./FilterMetro";

const Filters = () => {
  const appContext = useContext(AppContext);
  const { filterTag } = appContext;
  const showFilters = filterTag === "ANY" ? "" : "hidden";
  return (
    <div className={showFilters + " w-full pt-5"}>
      <FilterRank />
      <FilterState />
      <FilterIndustry />
      <FilterName />
      <FilterRevenue />
      <FilterGrowth />
      <FilterFounded />
      <FilterWorkers />
      <FilterYearsOnList />
      <FilterMetro />
    </div>
  );
};

export default Filters;
