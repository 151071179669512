import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { MdArrowUpward } from "react-icons/md";
import { MdArrowDownward } from "react-icons/md";

const SortButton = props => {
  const appContext = useContext(AppContext);
  const { endpointParms, sortRecords } = appContext;
  const { sortField, sortDirection } = props;
  const iconColor =
    endpointParms.sort === sortField &&
    endpointParms.sortDirection === sortDirection
      ? "text-green-600"
      : "";

  return (
    <button
      className=" font-inter text-xl leading-none px-0 py-0 focus:outline-none rounded"
      onClick={() => sortRecords(sortField, sortDirection)}
    >
      {sortDirection === "asc" ? (
        <MdArrowUpward className={iconColor + " hover:text-green-600"} />
      ) : (
        <MdArrowDownward className={iconColor + " hover:text-green-600"} />
      )}
    </button>
  );
};

export default SortButton;
