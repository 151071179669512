import React, { useContext } from "react";
import USAMap from "react-usa-map";
import { AppContext } from "../contexts/AppContext";
import SortButton from "./SortButton";

const FilterState = () => {
  const appContext = useContext(AppContext);
  const {
    endpointParms,
    showFilterUsstate,
    handleFilterUsstate,
    clearFilterUsstate,
    toggleFilterUsstate
  } = appContext;
  const showClear =
    endpointParms.usstate === "ANY" || endpointParms.usstate.length === 0
      ? "hidden"
      : "";
  const showFilter = showFilterUsstate ? "" : "hidden";
  const statusIndicator = showFilterUsstate ? "- " : "+ ";
  const filterColor = endpointParms.sort === "usstate" ? "text-green-600" : "";
  const width = 300;
  const height = 200;

  return (
    <section className="bg-white font-inter_semibold border border-gray-200 rounded  mb-1 mx-5 p-3">
      {/* FILTER HEADER */}
      <div className="flex">
        <div className="flex w-1/6 text-center ">
          <SortButton sortField="state" sortDirection="asc" />
          <SortButton sortField="state" sortDirection="desc" />
        </div>
        <div
          className="flex w-5/6 justify-between cursor-pointer "
          onClick={() => toggleFilterUsstate()}
        >
          <div className={filterColor + " justify-start hover:text-green-600"}>
            {statusIndicator + " State"}
          </div>
          <div className="justify-start text-gray-500">
            {endpointParms.usstate}
          </div>
        </div>
      </div>

      {/* FILTER BUTTONS */}
      <div className={showFilter + " flex flex-col mt-3"}>
        <div className="w-full justify-center py-2">
          <button
            className={
              showClear +
              " font-inter_semibold text-gray-600 px-4 py-1 mx-2 focus:outline-none rounded"
            }
            onClick={() => clearFilterUsstate()}
          >
            Clear
          </button>
        </div>

        <div className="flex justify-center">
          <USAMap width={width} height={height} onClick={handleFilterUsstate} />
        </div>
      </div>
    </section>
  );
};

export default FilterState;
