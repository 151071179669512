import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import TagFilterButton from "./TagFilterButton";

const FilterTag = () => {
  const appContext = useContext(AppContext);
  const { filterTag, clearFilterTag } = appContext;
  const showClear = filterTag === "ANY" ? "hidden" : "";

  return (
    <section className="flex">
      <button
        className={
          showClear +
          " font-inter_semibold text-gray-600 px-4 -mt-2 focus:outline-none rounded"
        }
        onClick={() => clearFilterTag()}
      >
        Clear
      </button>
      <TagFilterButton color="orange" />
      <TagFilterButton color="yellow" />
      <TagFilterButton color="green" />
      <TagFilterButton color="teal" />
    </section>
  );
};

export default FilterTag;
