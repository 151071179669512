import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";

const CompanyListButton = props => {
  const appContext = useContext(AppContext);
  const { handleSelectCurrentCompany } = appContext;
  const { textColor, companyId, companyName, rankField } = props;

  return (
    <button
      className={
        textColor +
        " w-full bg-white text-left text-base font-inter_semibold hover:text-green-600 border border-gray-200 focus:outline-none rounded px-2 py-1 mb-1"
      }
      onClick={() => handleSelectCurrentCompany(companyId)}
      key={companyId}
    >
      <div className="flex justify-between">
        <div className="justify-start">
          <span className="font-inter_light pr-3">{companyId} . </span>
          {" " + companyName}
        </div>
        <div className="justify-end font-inter_light">{rankField}</div>
      </div>
    </button>
  );
};

export default CompanyListButton;
