import React from "react";
import "./css/tailwind.css";
import { AppProvider } from "./contexts/AppContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CompanyList from "./components/CompanyList";
import Company from "./components/Company";
import Filters from "./components/Filters";
import SmallScreen from "./components/SmallScreen";

function App() {
  return (
    <div className="bg-gray-100">
      <div className="lg:hidden">
        <SmallScreen />
      </div>
      <div className="hidden lg:block">
        <AppProvider>
          <Header />
          <div className="flex xl:w-4/5 xl:mx-auto">
            <div className="flex w-1/3">
              <Filters />
            </div>
            <div className="flex w-1/3">
              <CompanyList />
            </div>
            <div className="flex w-1/3">
              <Company />
            </div>
          </div>
        </AppProvider>
      </div>
      <Footer />
    </div>
  );
}

export default App;
