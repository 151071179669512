import React, { useContext, useState } from "react";
import { AppContext } from "../contexts/AppContext";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

// A single form that can be used for logging in or signing up (registering).

const LoginSignup = props => {
  const appContext = useContext(AppContext);
  const {
    userNameInput,
    handleUserNameInput,
    userEmail,
    handleUserEmail,
    userPassword,
    handleUserPassword,
    login,
    signup
  } = appContext;
  const [hidePassword, setHidePassword] = useState(true);
  const showLogin = props.option === "login" ? "" : "hidden";
  const showSignup = props.option === "signup" ? "" : "hidden";
  const showName = props.option === "signup" ? "" : "hidden";
  const showHiddenPassword = hidePassword ? "" : "hidden";
  const showRevealedPassword = hidePassword ? "hidden" : "";
  function togglePassword() {
    setHidePassword(!hidePassword);
  }
  return (
    <div className="flex justify-between">
      <div className=""></div>

      <div className="flex justify-end">
        {/* USER NAME */}
        <input
          className={
            showName +
            " w-48 bg-gray-100 border border-gray-200 rounded py-1 px-2 mr-3"
          }
          type="text"
          placeholder="Name"
          value={userNameInput}
          onChange={handleUserNameInput}
        />

        {/* EMAIL */}
        <input
          className="w-48 bg-gray-100 border border-gray-200 rounded py-1 px-2 mr-3"
          type="text"
          placeholder="Email"
          value={userEmail}
          onChange={handleUserEmail}
        />

        {/* HIDDEN PASSWORD */}
        <div
          className={
            showHiddenPassword +
            " flex justify-between w-48 bg-gray-100 border border-gray-200 rounded px-2"
          }
        >
          <div className="justify-start">
            <input
              className="w-32 bg-gray-100 py-1 px-2"
              type="password"
              placeholder="Password"
              value={userPassword}
              onChange={handleUserPassword}
            />
          </div>
          <div className="justify-end">
            <button
              className="focus:outline-none pt-2"
              onClick={() => togglePassword()}
            >
              <FaRegEye />
            </button>
          </div>
        </div>

        {/* REVEALED PASSWORD */}
        <div
          className={
            showRevealedPassword +
            " flex justify-between w-48 bg-gray-100 border border-gray-200 rounded px-2"
          }
        >
          <div className="justify-start">
            <input
              className="w-32 bg-gray-100 py-1 px-2"
              type="text"
              placeholder="Password"
              value={userPassword}
              onChange={handleUserPassword}
            />
          </div>
          <div className="justify-end">
            <button
              className="focus:outline-none pt-2"
              onClick={() => togglePassword()}
            >
              <FaRegEyeSlash />
            </button>
          </div>
        </div>

        {/* LOGIN BUTTON */}
        <button
          className={
            showLogin +
            " font-inter_semibold text-gray-600 hover:text-white hover:bg-green-400 " +
            "border border-gray-200 px-4 py-1 ml-3 focus:outline-none rounded"
          }
          onClick={() => login()}
        >
          Login
        </button>

        {/* SIGNUP BUTTON */}
        <button
          className={
            showSignup +
            " font-inter_semibold text-gray-600 hover:text-white hover:bg-green-400 " +
            "border border-gray-200 px-4 py-1 ml-3 focus:outline-none rounded"
          }
          onClick={() => signup()}
        >
          Signup
        </button>
      </div>
    </div>
  );
};

export default LoginSignup;
