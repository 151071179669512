import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";

const Pagination = props => {
  const appContext = useContext(AppContext);
  const { handleChangePage } = appContext;
  const { data } = props;

  if (props === null) return null;
  const activeButton = "bg-white hover:text-green-600 cursor-pointer";
  const inactiveButton = "bg-gray-200 text-gray-400 cursor-default";
  const firstButton = data.current_page === 1 ? inactiveButton : activeButton;
  const lastButton =
    data.current_page === data.last_page ? inactiveButton : activeButton;
  const prevButton =
    data.prev_page_url === null ? inactiveButton : activeButton;
  const nextButton =
    data.next_page_url === null ? inactiveButton : activeButton;

  return (
    <section className="font-inter_semibold ">
      <div className="flex justify-center w-full mb-2">
        <button
          className={
            firstButton +
            " font-inter_semibold border border-gray-200 px-4 py-1 mx-2 focus:outline-none rounded"
          }
          onClick={() => handleChangePage(data.first_page_url)}
        >
          1
        </button>
        <button
          className={
            prevButton +
            " font-inter_semibold border border-gray-200 px-4 py-1 mx-2 focus:outline-none rounded"
          }
          onClick={() => handleChangePage(data.prev_page_url)}
        >
          Prev
        </button>
        <div className="py-1 mx-2">{data.current_page}</div>
        <button
          className={
            nextButton +
            " font-inter_semibold border border-gray-200 px-4 py-1 mx-2 focus:outline-none rounded"
          }
          onClick={() => handleChangePage(data.next_page_url)}
        >
          Next
        </button>

        <button
          className={
            lastButton +
            " font-inter_semibold border border-gray-200 px-4 py-1 mx-2 focus:outline-none rounded"
          }
          onClick={() => handleChangePage(data.last_page_url)}
        >
          {data.last_page}
        </button>
      </div>
    </section>
  );
};

export default Pagination;
